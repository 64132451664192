<template>
  <div class="sellCoin-wrap">

    <div class="sellCoin-main-wrap">
      <van-field v-model="saleAmountValue"
                 :formatter="formatter"
                 :placeholder="locale === 'vi' ? `${t('输入出售数量')}` : `${t('输入出售数量')}（${store.state.publicConfig.title}）`"
                 autocomplete="off"
                 center
                 type="text"
                 @update:model-value="handleUpdateAmount"
      >
        <template #left-icon>
          <van-image :src="themeImgs.commonTansIcon2"/>
        </template>
      </van-field>

      <p class="sale-amount">{{ t('可售余额') }}：<span>{{ availableBalance }}</span> <template v-if="locale !== 'vi'">{{ store.state.publicConfig.title }}</template></p>

      <p v-show="minSaleAmount" class="sale-amount">{{ t('单次出售最小数量') }}：<span class="min-amount">{{ minSaleAmount }}</span></p>

      <div class="payment-method-wrap">
        <span class="label">{{ t('收款方式') }}：</span>
        <div class="payment-method-box">
          <div v-for="type in paymentWayList" :key="type" :class="{'active':paymentIdArr.includes(type)}"
               class="method-item" @click="selectPaymentWay(type)">
            <van-image v-if="locale === 'vi'" :src="themeImgs[`commonPaymentIconVn${type}`]"/>
            <van-image v-else :src="themeImgs[`commonPaymentIcon2${type}`]"/>

          </div>
        </div>
      </div>

<!--      <div v-if="siteName !== 'qtpay'" class="sale-method-wrap">-->
<!--        <span class="label">出售方式</span>-->
<!--        <div class="sale-method-box">-->
<!--          <div :class="{'active':sellWayId == 2}" class="method-item" @click="sellWayId=2">不拆分</div>-->
<!--          <div :class="{'active':sellWayId == 1}" class="method-item" @click="sellWayId=1">拆分</div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="sale-method-wrap" v-if="locale !== 'vi' && siteName !== 'qtpay'">
        <span class="label">{{ t('出售方式') }}</span>
        <div class="sale-method-box">
          <div :class="{'active':sellWayId == 2}" class="method-item" @click="sellWayId=2">{{ t('不拆分') }}</div>
          <div :class="{'active':sellWayId == 1}" class="method-item" @click="sellWayId=1">{{ t('拆分') }}</div>
        </div>
      </div>

      <div class="sale-btn-box">
        <van-button :disabled="availableBalance == '0.00' || btnClock" block @click="handleSale">{{ t('出售') }}</van-button>
      </div>
    </div>

    <!-- 支付密码弹出框 -->
    <van-dialog v-model:show="showPayPasswordDialog"
                :before-close="onBeforeClose"
                show-cancel-button :title="t('请输入支付密码')"
                teleport="body"
                class="sellCoin-payPsd-dialog"
    >
      <van-field
          v-model="pay_password"
          class="cancel-order-dialog-cell"
          :label="t('支付密码')"
          maxlength="6"
          :placeholder="t('请输入支付密码')"
          type="password"
      />
    </van-dialog>
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from "vue-router"
import {Dialog, Toast} from 'vant'
import {doSell, getMemberAmount, getPaymentList} from '@/api/api'
import { useI18n } from 'vue-i18n'

export default {
  emits: ['close'],
  setup(props, ctx) {
    const { t, locale } = useI18n()

    const siteName = ref(siteChannel)
    const store = useStore()
    const loading = ref(false)
    const $router = useRouter()

    // 获取余额
    const handleGetMemberAmount = () => {
      getMemberAmount().then(res => {
        if (res.result == 200000) {
          availableBalance.value = res.data.info.currency_money
        }
      })
    }
    // 获取收付款列表数据
    const handleGetPaymentList = () => {
      getPaymentList().then(res => {
        if (res.result == 1032) {
          $router.push('/home')
          return Toast.fail(res.msg)
        }
        if (res.result == 200000) {
          if (JSON.stringify(res.data) == '{}') {
            Dialog.confirm({
              title: t('提示'),
              message: t('请先添加收付款方式'),
              className: 'take-down-dialog'
            })
                .then(() => {
                  store.commit('setPaymentDialogFlag', true)
                })
                .catch(() => {
                  ctx.emit('close')
                });
          } else {
            for (let key in res.data) {
              if (res.data[key].status == 1) {
                paymentWayList.value.push(res.data[key].type)
                // 排序，银行放第一位
                paymentWayList.value.sort((a, b) => b - a)
              }
            }
          }
        }
      })
    }
    onMounted(() => {
      handleGetMemberAmount()
      handleGetPaymentList()
    })
    // 定义出售的最少数量
    const minSaleAmount = ref(Number(store.state.sysConfig.min_amount))
    // 可售余额
    const availableBalance = ref(0)
    // 只能输入整数跟2位小数
    const formatter = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]
    // 出售数量
    const saleAmountValue = ref('')
    // 展示出售数量
    const labelSaleAmount = ref(0)
    // 输入框输入值
    const handleUpdateAmount = (value) => {
      if (!value) {
        labelSaleAmount.value = 0
      } else {
        labelSaleAmount.value = value
      }
    }
    // 定义收款方式列表
    const paymentWayList = ref([])
    // 存储多选的收款方式id值
    const paymentIdArr = ref([])
    // 选择收款方式
    const selectPaymentWay = (i) => {
      if (paymentIdArr.value.includes(i)) {
        //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
        //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
        paymentIdArr.value = paymentIdArr.value.filter(function (ele) {
          return ele != i;
        });
      } else {
        paymentIdArr.value.push(i);
      }
    }
    // 出售方式
    const sellWayId = ref(2)
    // 是否显示支付密码弹框
    const showPayPasswordDialog = ref(false)
    // 支付密码变量
    const pay_password = ref('')
    // 取消默认关闭弹框事件
    const onBeforeClose = (action) => {
      // 点击了确定按钮
      if (action === "confirm") {
        if (!pay_password.value) {
          Toast.fail(t('请输入支付密码'))
          return false
        } else {
          handleSDosale()
        }
      }
      // 点击了取消按钮
      else {
        showPayPasswordDialog.value = false
        pay_password.value = ''
        btnClock.value = false
      }
    }
    // 点击出售按钮
    const handleSale = () => {
      if (btnClock.value) return
      if (!saleAmountValue.value) {
        return Toast.fail(t('请输入出售数量'))
      } else if (saleAmountValue.value < minSaleAmount.value) {
        return Toast.fail(t('出售数量不能小于单次最小数量'))
      } else if (!paymentIdArr.value.length) {
        return Toast.fail(t('请选择收款方式'))
      } else {
        showPayPasswordDialog.value = true
      }
      btnClock.value = true
    }
    // 确定出售
    const handleSDosale = () => {
      if (loading.value) {
        return
      }

      loading.value = true
      let params = {
        amount: saleAmountValue.value,
        pay_type: paymentIdArr.value.sort((a, b) => {
          return a - b
        }).toString(),
        type: sellWayId.value,
        pay_password: pay_password.value
      }
      doSell(params).then(res => {
        loading.value = false
        btnClock.value = false
        if (res.result == 200000) {
          showPayPasswordDialog.value = false
          pay_password.value = ''
          Toast.success(t('上架成功'))
          saleAmountValue.value = ''
          paymentIdArr.value = []
          labelSaleAmount.value = 0
          sellWayId.value = 2
          handleGetMemberAmount()
          ctx.emit('close')
        } else {
          Toast.fail(res.msg)
        }
      }).catch(error => {
        loading.value = false
        btnClock.value = false
        Toast.fail(t('操作失败，请检查网络重试或者联系客服'))
      })
    }
    // 按钮锁
    const btnClock = ref(false)
    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)
    return {
      minSaleAmount,
      availableBalance,
      saleAmountValue,
      labelSaleAmount,
      handleUpdateAmount,
      paymentWayList,
      selectPaymentWay,
      paymentIdArr,
      sellWayId,
      handleSale,
      formatter,
      btnClock,
      themeImgs,
      showPayPasswordDialog,
      pay_password,
      onBeforeClose,
      store,
      siteName,
      t,
      locale
    }
  }
}
</script>

<style lang="less" scoped>
.sellCoin-wrap {
  .sellCoin-main-wrap {
    padding: 0 20px 23px;
    //background: var(--mainBgColor);

    :deep(.van-field) {
      height: 40px;
      border-radius: 8px;
      background: #DFFBF0;

      &::after {
        border: none;
      }

      .van-field__left-icon {
        display: flex;
        align-items: center;
        margin-right: 21px;

        .van-image {
          width: 20px;
          height: 20px;
        }
      }

      .van-field__label {
        width: 36%;
        font-weight: 500;
        font-size: 26px;
        //color: var(--textColor);
        color: #535C66;
      }
    }

    .sale-amount {
      margin-top: 20px;
      //color: var(--textColor);
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #333;

      &:last-of-type {
        margin-top: 24px;
      }

      span {
        font-size: 16px;
        font-weight: 700;

        &.min-amount {
          font-weight: 600;
        }
      }
    }

    .payment-method-wrap {
      display: flex;
      align-items: center;
      margin-top: 21px;

      .label {
        margin-right: 22px;
        //color: var(--textCommonColor);
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #333;
      }

      .payment-method-box {
        display: flex;
        align-items: center;

        .method-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          margin-right: 15px;

          &.active {
            border: 1px solid var(--textColor);
            border-radius: 8px;
          }

          .van-image {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .sale-method-wrap {
      display: flex;
      align-items: center;
      margin-top: 32px;

      .label {
        margin-right: 11px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #333;
      }

      .sale-method-box {
        display: flex;
        align-items: center;

        .method-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 28px;
          margin-right: 17px;
          border-radius: 12px;
          //color: var(--textColor);
          //background: var(--notSplitBgColor);
          color: #666;
          background: #E3E3E3;
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: 28px;
          text-align: center;

          &.active {
            background: #2EBD85;
            color: #FFF;
          }
        }
      }
    }

    .sale-btn-box {
      margin-top: 48px;
      border-radius: 8px;

      .van-button {
        border-radius: 8px;
        background: #2EBD85;
      }
    }
  }
}
</style>

<style lang="less">
.sellCoin-payPsd-dialog {
  .van-dialog__header {
    padding: 16px 0;
    font-weight: 500;
    font-size: 18px;
    //color: var(--orderDialogTextColor);
    color: #535C66;
  }

  .van-button--default {
    font-family: PingFang SC;
    font-size: 17px;
    font-weight: 400;
    color: #535C66;
  }

  .cancel-order-dialog-cell {
    flex-direction: column;
    background: none;

    input::placeholder {
      color: rgba(83, 92, 102, 0.6);
    }

    textarea::placeholder {
      color: rgba(83, 92, 102, 0.6);
    }

    .van-field__label {
      margin-bottom: 7px;

      label {
        color: #535C66;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    .van-field__control {
      color: #535C66;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.take-down-dialog {
  .van-dialog__header {
    padding: 16px 0;
    font-weight: 500;
    font-size: 18px;
    color: #535C66;
  }

  .van-dialog__message {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: center;
    color: rgba(83, 92, 102, 0.9);
  }

  .van-button__text {
    font-family: PingFang SC;
    font-size: 17px;
    font-weight: 400;
    color: #535C66;
  }
}
</style>


