<template>
  <van-popup v-model:show="show"
             position="left"
             @close="emit('update:showSideBar', false)"
             :style="{ height: '100%' }"
             class="sidebar-popup"
             :class="{'is-vn': locale === 'vi'}"
  >
    <div class="sidebar">
      <div class="logo-status">
        <van-image class="logo" :src="require('@siteResources/logo/home_logo.png')" />
        <div v-if="token" :class="`is-name ${isNameObj.className}`">{{ isNameObj.text }}</div>

        <van-image class="menu-left" v-else :src="themeImgs.menuLeft" @click="emit('update:showSideBar', false)"/>
      </div>

      <div class="user-info">
<!--      <div class="user-info" @click="token ? router.push('/personalData') : null">-->
        <!--        <van-image class="avatar" round :src="getAvatar(userInfo.avatarNew)" />-->
        <van-image class="avatar" round :src="getAvatar(userInfo.avatarNew)" />
        <div class="realname-box">
          <template v-if="token">
            <span class="name">{{ userInfo.nick_name }}</span>
            <div class="wallet">
              <i :style="{ backgroundImage: `url('${themeImgs.wallet}')` }" />
              <span>{{ store.state.memberAmountObj && store.state.memberAmountObj.currency_money || '0.00' }}</span>
            </div>
          </template>

          <template v-else>
            <span class="name">{{ t('您还未登录') }}</span>
            <div class="login-register"><span class="yellow" @click="openLogin(1)">{{ t('登录') }}</span>/<span class="green" @click="openLogin(2)">{{ t('注册') }}</span></div>
          </template>

        </div>
      </div>

      <div class="address">
        <span class="title">{{ t('钱包地址') }}:</span>
        <div>
          <span v-if="token">{{ userInfo.wallet_address }}</span>
          <span v-else>******</span>

          <van-image v-if="token" :src="themeImgs.commonCopyIcon" @click="copyAddress(userInfo.wallet_address)" />
        </div>

      </div>

      <div class="menu-list">
        <template v-for="item in menuList">
          <div class="item"
               :class="{ active: currentKey === item.key, isVn: locale === 'vi' }"
               :key="item.key"
               @click="handleClick(item)"
               v-if="item.isShow"
          >
            <van-image :src="themeImgs[item['key']]" />
            <span>{{ item.title }}</span>
          </div>
        </template>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { computed, defineProps, defineEmits, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useGetAvatar} from "@hooks";
import wsConnection from "@/utils/websocket"
import useClipboard from "vue-clipboard3";
import { Toast } from "vant";
import { logout } from '@/api/api'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()

// defineOptions({
//   name: 'SideBar',
// });

const props = defineProps({
  showSideBar: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:showSideBar'])

const router = useRouter()
const store = useStore()
const userInfo = computed(() => store.state.userInfos)

const { getAvatar } = useGetAvatar()

const isNameObj = computed(() => {
  let text = t('未实名')
  let className = 'green'
  if (userInfo.value.is_name === 2) {
    text = t('已实名')
    className = 'yellow'
  }

  if (userInfo.value.is_name === 3) {
    text = t('审核中')
    className = 'orange'
  }

  return { text, className }
})

// 复制钱包地址
const { toClipboard } = useClipboard()
const copyAddress = async (content) => {
  try {
    await toClipboard(content)
    Toast.success(t('复制成功'))
  } catch (e) {
    console.error(e)
  }
}

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const token = computed(() => store.state.loginInfos.token)

const menuList = computed(() => {
  const list = [
    {
      key: 'certification',
      title: t('实名认证'),
      path: '/certification',
      isShow: ![2].includes(userInfo.value.is_name) && store.state.isApp
    },
    {
      key: 'scan',
      title: t('扫一扫'),
      path: '',
      isShow: store.state.isApp
    },
    {
      key: 'orderList',
      title: t('交易订单'),
      path: '/order',
      isShow: true
    },
    {
      key: 'payment',
      title: t('收款信息'),
      path: '',
      isShow: true
    },
    {
      key: 'fundRecord',
      title: t('资金明细'),
      path: '/fundRecord',
      isShow: true
    },
    {
      key: 'personalReport',
      title: t('个人报表'),
      path: '/personalReport',
      isShow: true
    },
    {
      key: 'agencyCommission',
      title: t('代理佣金'),
      path: '/agencyCommission',
      isShow: true
    },
    {
      key: 'aboutUs',
      title: t('关于我们'),
      path: '',
      isShow: true
    },
    {
      key: 'helpCenter',
      title: t('帮助中心'),
      path: '/helpCenter',
      isShow: true
    },
    {
      key: 'onlineService',
      title: t('在线客服'),
      path: '',
      isShow: true
    },
    {
      key: 'modifyLoginPassword',
      title: t('修改登录密码'),
      path: '/modifyLoginPassword',
      isShow: true
    },
    {
      key: 'modifyPaymentPassword',
      title: t('修改支付密码'),
      path: '/modifyPaymentPassword',
      isShow: true
    },
    {
      key: 'logout',
      title: t('退出登录'),
      path: '',
      isShow: token.value
    }
  ]

  return list
})

const show = ref(false)

watch(() => props.showSideBar, val => {
  show.value = val
})

// 退出登录
const handleDoLogout = () => {
  logout().then(res => {
    if(res.result === 200000) {
      Toast.success(t('退出成功'))
      localStorage.removeItem("loginInfos")
      store.dispatch("saveLoginInfosActions", {});

      if(wsConnection.$ws) {
        wsConnection.$ws.close()
      }
      // 针对综合的退出
      if(window.parent.frames['agIframe']) {
        window.parent.postMessage('退出登录', store.state.loginInfos.originUrl)
      } else {
        // router.push('/login');
      }
    }
  })
}

const openLoginReg = (type = 1) => {
  store.commit('setLoginRegisterInfo', { flag: true, type }) // 打开登录注册弹框
}

const currentKey = ref('')

const handleClick = ({ key, path }) => {
  currentKey.value = key

  if (path) {
    router.push(path)
  }

  if (key === 'logout') {
    handleDoLogout()
  }

  if (key === 'onlineService') {
    store.commit('setShowServicePopup', true)
  }

  if (key === 'payment') {
    if (!token.value) {
      openLoginReg()
      emit('update:showSideBar', false)
      return
    }
    store.commit('setPaymentDialogFlag', true)
  }

  if (key === 'aboutUs') {
    store.commit('setAboutUsDialogFlag', true)
  }

  if (key === 'scan') {
    openScanCodeUrl()
  }

  emit('update:showSideBar', false)
}

const openLogin = (type) => {
  openLoginReg(type)
  emit('update:showSideBar', false)
}

// 点击扫码
const openScanCodeUrl = () => {
  // 调用APP端的方法并传参给APP端
  if (window.Certification && window.Certification.scanQRCode) { // 安卓
    // 传参给Android
    try {
      window.Certification.scanQRCode();
    } catch (error) {
      console.log(error);
    }
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.scanQRCode) {
    // 传参给IOS
    try {
      window.webkit.messageHandlers.scanQRCode.postMessage({});
    } catch (error) {
      console.log(error);
    }
  }
}
</script>



<style lang='less' scoped>
.sidebar {
  padding-top: 31px;
  border-radius: 0 20px 20px 0;

  .logo-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 6px;
    margin-bottom: 20px;

    .logo {
      flex-shrink: 0;
      width: 104px;
      //height: 29px;
    }

    .is-name {
      height: 16px;
      padding: 0 8px;
      font-family: PingFang SC;
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      color: rgba(255, 255, 255, 1);
      border-radius: 3px;

      &.green {
        background: rgba(46, 189, 133, 1);
      }

      &.yellow {
        background: rgba(233, 181, 0, 1);
      }

      &.orange {
        background: rgba(230, 125, 1, 1);
      }
    }

    .menu-left {
      flex-shrink: 0;
      width: 21px;
      height: 14px;
    }

  }

  .user-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 16px;

    .avatar {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-right: 13px;
    }

    .realname-box {

      .name {
        padding-right: 4px;
        word-break: break-all;
        display: inline-block;
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        color: rgba(51, 51, 51, 1);
      }

      .wallet {
        display: flex;
        align-items: center;
        margin-top: 12px;

        i {
          //display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 3.6px;
          background-size: 100% 100%;
        }

        span {
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: rgba(102, 102, 102, 1);
        }
      }

      .login-register {
        margin-top: 7px;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #51545C;

        .yellow {
          color: #FDD431;
        }

        .green {
          color: #2EBD85;
        }
      }
    }
  }

  .address {
    margin-bottom: 15px;
    padding: 0 11px 0 16px;
    font-family: Inter;
    font-weight: 400;
    color: #2EBD85;
    font-size: 14px;

    .title {
      //font-size: 18px;
      line-height: 17px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      //> span {
      //  font-size: 14px;
      //}

      .van-image {
        width: 16px;
        height: 16px;
      }
    }
  }

  .menu-list {
    .item {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 23px;

      &.isVn {
        padding: 0 10px;

        .van-image {
          margin-right: 16px;
        }
      }

      &.active {
        background: #E5FFF5;
      }

      .van-image {
        flex-shrink: 0;
        width: 23px;
        height: 23px;
        margin-right: 24px;
      }

      span {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        white-space: nowrap;
      }

      //i {
      //  position: absolute;
      //  right: 23px;
      //  width: 9px;
      //  height: 16px;
      //  background-size: 100% 100%;
      //}
    }
  }
}
</style>

<style lang='less'>
.sidebar-popup {
  width: 210px;
  border-radius: 0 20px 20px 0;

  &.is-vn {
    width: 250px;
  }
}
</style>
